<template>
  <div class="signin position-relative">
    <div class="d-flex">
      <div class="ms-auto" @click="GotoMain()" style="z-index: 10">
        <i class="fal fa-home-alt p-3 text-gray-relative-500"></i>
      </div>
    </div>
    <Loading v-if="loading" />
    <div class="px-3 position-absolute top-50 start-50 translate-middle w-100">
      <div class="mx-auto">
        <div class="my-3">
          <img
            src="https://golfandyacht.s3.ap-northeast-2.amazonaws.com/1691532559818logo500x500.png"
            width="100%"
            class="rounded mx-auto d-block"
          />
        </div>
      </div>
      <div class="mx-auto my-auto">
        <div>
          <div class="px-0">
            <div role="form">
              <div class="mb-1">
                <input
                  type="text"
                  class="form-control bg-body py-3"
                  v-model="id"
                  placeholder="아이디(이메일주소)"
                />
              </div>
              <div class="mb-3">
                <input
                  type="password"
                  class="form-control bg-body py-3"
                  v-model="password"
                  placeholder="비밀번호"
                  autocomplete="none"
                  @keyup.enter="Login()"
                />
              </div>
              <div class="text-center mt-3">
                <button
                  type="button"
                  class="btn btn-main thick w-100 btn-lg text-white shadow-sm"
                  @click="Login()"
                >
                  <small>로그인</small>
                </button>
              </div>
              <div class="mb-2 mt-3">
                <div class="text-center small">
                  <span class="ms-2">
                    <router-link
                      to="/signup"
                      class="text-gray-relative-500 pe-3 cusor-pointer border-end"
                    >
                      회원가입
                    </router-link>
                    <router-link
                      to="/find-pw"
                      class="text-gray-relative-500 ps-3 cusor-pointer"
                    >
                      비밀번호를 잊으셨나요?
                    </router-link>
                  </span>
                </div>
              </div>
              <div class="mb-2 mt-5">
                <div class="text-left small">
                  <span class="small me-2">
                    <i class="fa fa-info-circle px-2"></i>홈페이지
                  </span>
                  <span class="small">
                    <a href="https://golf-yacht.com"
                      ><span
                        class="text-secondary text-decoration-underline cursor-pointer"
                        >https://golf-yacht.com</span
                      ></a
                    >
                  </span>
                </div>
                <div class="text-left small">
                  <span class="small me-2">
                    <i class="fa fa-info-circle px-2"></i>문의하기
                  </span>
                  <span class="small">
                    <a href="mailto:golf-yacht@golf-yacht.com"
                      ><span
                        class="text-secondary text-decoration-underline cursor-pointer"
                        >golf-yacht@golf-yacht.com</span
                      ></a
                    >
                  </span>
                </div>

                <div class="text-left small">
                  <span class="small me-2">
                    <i class="fa fa-info-circle px-2"></i>문의하기
                  </span>
                  <span class="small">
                    <a href="tel:1566-7125"
                      ><span
                        class="text-secondary text-decoration-underline cursor-pointer"
                        >1566-7125</span
                      ></a
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import Loading from '@/components/common/Loading.vue';
const CryptoJS = require('crypto-js');

export default {
  components: {
    SubHeader,
    Loading,
  },
  data() {
    return {
      id: '',
      password: '',
      loading: false,
      push_token: '',
    };
  },
  methods: {
    GotoMain() {
      // alert("?")
      this.$router.push({ path: '/' });
    },
    ReceiveGiftHold(token, phone) {
      const body = { token, phone };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.$http.post('/front/login/ReceiveGiftHold', { req }).then((res) => {
        if (res.data.code == 200) {
          const count = res.data.gift_count;
        }
      });
    },
    Login() {
      const id = this.id;
      const password = this.password;
      const push_token = this.push_token;
      const body = { id, password, push_token };

      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.loading = true;
      this.$http.post('/front/login/Login', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);

            const login = true;
            const name = body.name;
            const token = body.token;
            const phone = body.phone;
            this.$cookies.set('LOGIN_EMAIL', id);
            const mem_info = { login, name, token, phone };

            this.$store.dispatch('SETLOGIN', { mem_info }).then(async () => {
              await this.ReceiveGiftHold(token, phone);
              // this.$alert(`<i class="fal fa-check-circle fa-2x mb-2 text-success"></i><p class="small mt-1">로그인 완료하였습니다.</p>`).then(()=>{
              //     window.location.href="/";
              // })
              window.location.href = '/';
            });
          } else if (res.data.code == '300') {
            this.$alert(
              '<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">사용자 이메일 계정<br>정보를 확인해주세요.</p>'
            );
          } else if (res.data.code == '100') {
            this.$alert(
              '<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">사용자 이메일 계정이<br>존재하지 않습니다.</p>'
            );
          } else if (res.data.code == '500') {
            this.$alert(
              '<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">사용자 이메일 계정이<br>차단되었습니다.</p>'
            );
          }
        }
      });
    },
    GetToken() {
      try {
        if (/Android/i.test(navigator.userAgent)) {
          HybridApp.saveToken();
        }
      } catch (e) {
        console.log('shareText ' + e);
      }
    },
    getAndroidToken(token) {
      this.push_token = token;
    },
  },
  mounted() {
    if (this.$cookies.get('LOGIN_EMAIL') != null) {
      this.id = this.$cookies.get('LOGIN_EMAIL');
    }

    window.login = this;
    this.GetToken();
    this.$EventBus.$emit('HideNav');
  },
  destroyed() {
    this.$EventBus.$emit('ShowNav');
  },
};
</script>

<style lang="scss" scoped>
.signin {
  height: calc(100vh - 55px);
  min-height: 500px;
  max-width: 400px;
  margin: 0 auto;
}

.form-control {
  font-weight: bold;

  &::placeholder {
    font-weight: normal;
  }
}
.cusor-pointer {
  cursor: pointer;
}
</style>
