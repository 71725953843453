<template>
    <div class="find_email  position-relative">
        <SubHeader :title="title" :link="'/signin-email'" :alert="false" :bookmark="false" />
        <Loading v-if="loading"/>
        <div class="mx-4">
            <h4 class="mt-4">
                가입하신 이메일 주소를<br>입력해주세요.
            </h4>
            <ul class="small my-4">
                <li class="d-flex lh-sm mb-1"><small class="text-gray-relative-500">해당 이메일로 새 비밀번호를 전송해드립니다.</small></li>
            </ul>
        </div>
        <div class="sign_box vstack gap-2 w-100 px-3 mt-5">
            <div class="form-group">
                <input required type="email"  class="form-control thick bg-body text-body border-gray-relative-300" v-model="email" placeholder="이메일 주소 (example@email.com)">
            </div>
            <div @click="ChkEmail()" class="btn btn-main thick text-white mt-1">확인</div>
        </div>
        
        <!-- <div class="text-center">
            <i class="fal fa-exclamation-circle fa-3x mb-3 text-danger"></i>
            <p class="small">
                사용자 이메일 계정이<br>
                존재하지 않습니다.
            </p>
        </div>

            <router-link class="btn btn-primary w-100 mt-4 thick small" to="/signin">로그인 하기</router-link>
        <div class="text-center">
        </div> -->
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Loading from '@/components/common/Loading.vue'

const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader,Loading
    },
    data(){
        return{
            title: '비밀번호 재설정',

            email: '',
            loading : false,

        }
    },
    methods:{
        ChkEmail(){
            const email = this.email;
            
            const body = {email};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/sign/ResetPasswordEmail',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$alert(`<i class="fal fa-check-circle fa-2x mb-2 text-success"></i><p class="small mt-1">임시 비밀번호가<br>${this.email}로<br>발송되었습니다.</p>`).then(()=>{
                                this.$router.push('/signin')
                            })
                        }else if(res.data.code =="100"){
                            this.$alert('<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">사용자 이메일 계정이<br>존재하지 않습니다.</p>')
                        }
                    }
                }
            );
        },
    },
    mounted(){
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>
    .find_email{
        height: 100vh;
        min-height: 500px;
    }

    .form-control{
        font-weight: bold;

        &::placeholder{
            font-weight: normal;
        }
    }

</style>